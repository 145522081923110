import React, { Component } from "react";
import Img from "gatsby-image";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";

import artshaMangata from "../images/kazenonaka_artsha_mangata.jpg"
import jacketMangata from "../images/mangata_jacket.png"



export default class PhotosPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePopup: false,
      selectedItem: 0
    };
  }

  render() {
    const { data } = this.props;
    const { activePopup, selectedItem } = this.state;

    return (
      <Layout>
        <SEO
          title="Photos"
          keywords={[`Rohit Gupta`, `Frontend Developer`, `Developer`, `Photo`]}
        />
        <div className="site-container blogs-page" id="Blogs">
          <div className="container">
            <div className="section-head">
              <h1 className="line-heading h2">Photos</h1>
            </div>
            <div>
              アーティスト写真 (<a href={artshaMangata} download="kazenonaka_artist_photo.jpg">クリックしてdownload</a>)
              <img src={artshaMangata} alt="アーティスト写真" />
            </div>
            <hr/>
            <div>
              mangata アルバムジャケット (<a href={jacketMangata} download="kazenonaka_mangata_photo.jpg">クリックしてdownload</a>)
              <img src={jacketMangata} alt="mangata アルバムジャケット" />
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}
export const pageQuery = graphql`
  query PhotosPageQuery {
    contentfulPhotos {
      photos {
        file {
          url
        }
        fluid(maxWidth: 600) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
  }
`;
